import React from 'react'
import {graphql} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import {MDXRenderer} from "gatsby-plugin-mdx";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import components from "../components/mdx_components";
import Box from "@material-ui/core/Box";

const BlogPage = ({data}) => {
  const {frontmatter, body} = data.mdx

  return (
    <Container maxWidth={"lg"}>
      <Box m={1} align={"center"}>
        <Typography variant={"h2"}>{frontmatter.title}</Typography>
        <MDXProvider components={components}><MDXRenderer>{body}</MDXRenderer></MDXProvider>
      </Box>
    </Container>
  )
}
export const pageQuery = graphql`
    query($slug: String!) {
        mdx( slug: { eq: $slug } ) {
            body
            slug
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
            }
        }
    }
`

export default BlogPage
