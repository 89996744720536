import {Link} from "gatsby-theme-material-ui"
import React from "react";
import Typography from "@material-ui/core/Typography";

const H1 = ({children}) => <Typography variant={"h1"} gutterBottom={true}>{children}</Typography>
const H2 = ({children}) => <Typography variant={"h2"} gutterBottom={true}>{children}</Typography>
const H3 = ({children}) => <Typography variant={"h3"} gutterBottom={true}>{children}</Typography>
const H4 = ({children}) => <Typography variant={"h4"} gutterBottom={true}>{children}</Typography>
const P = ({children}) => <Typography variant={"body1"} gutterBottom={true}>{children}</Typography>
const A = (props) => <Link {...props} color="secondary">{props.children}</Link>

const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  p: P,
  a: A
}

export default components
